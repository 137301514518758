import classNames from 'classnames';
import type { ContainerWidth } from 'ui/components/4-habitats/Container/Container';
import { Container } from 'ui/components/4-habitats/Container/Container';
import styles from './ContentSection.module.scss';

export interface ContentSectionProps {
	width: ContainerWidth;
	title: string;
	centered?: boolean;
	children?: React.ReactNode;
}

export const ContentSection: React.FC<ContentSectionProps> = ({ children, width, centered, title }) => {
	return (
		<section aria-label={title} className={classNames(styles.Section, { [styles.Section___centered]: centered })}>
			<div className={styles.Section_content}>{children}</div>
		</section>
	);
};
